import styled from "styled-components";

const NavigationWrapper = styled.ul`
    list-style: none;
    display: flex;
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
`;

export default NavigationWrapper;
